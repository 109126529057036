<template>
    <div class="auth" @click="auth">授权中...</div>
</template>

<script>
// const url = localStorage.getItem("now_url"); // 如果没有的话可以做个默认值处理,这里没有用到，只是提示一下可以在这儿用或者自行找时机使用

export default {
    //生命周期函数
    created() {
        document.title = '授权页';
        
    },
    data() {
        return {};
    },
    methods: {
        auth() {
            const code = this.GetUrlParame("code"); // 截取code，授权返回的code
            let appid = 'wxe45e400c8c7df754'; // 可以通过后台获取
            if (!code) {
                //没有code
                let authPageBaseUri =
                    "https://open.weixin.qq.com/connect/oauth2/authorize";
                //这里的 redirect_uri 用的当前页面地址，记得用 encodeURIComponent 做一下编码，这儿不注意容易报错
                let authParams = "";
                authParams = `?appid=${appid}&redirect_uri=${encodeURIComponent(
                    window.location.href.split("#")[0] + "#/login" + this.$route.fullPath
                )}&response_type=code&scope=snsapi_base&state=ceshi#wechat_redirect`;
                console.log(authPageBaseUri + authParams)
                console.log(window.location.href.split("#")[0] + "#/login")
               
                window.location.href = authPageBaseUri + authParams
               
            } else {
                //有code 拿到code去后台换数据 token openid等
                console.log(code)
            }
        },
        // 截取code
        GetUrlParame(parameName) {
            /// 获取地址栏指定参数的值
            // 获取url中跟在问号后面的部分
            var parames = window.location.search;
            // 检测参数是否存在
            if (parames.indexOf(parameName) > -1) {
                var parameValue = "";
                parameValue = parames.substring(
                    parames.indexOf(parameName),
                    parames.length
                );
                // 检测后面是否还有参数
                if (parameValue.indexOf("&") > -1) {
                    // 去除后面多余的参数, 得到最终 parameName=parameValue 形式的值
                    parameValue = parameValue.substring(
                        0,
                        parameValue.indexOf("&")
                    );
                    // 去掉参数名, 得到最终纯值字符串
                    parameValue = parameValue.replace(parameName + "=", "");
                    return parameValue;
                }
                return "";
            }
        }
    }
};
</script>

<style lang="less" scoped>
.auth {
    text-align: center;
    font-size: 64px;
    padding-top: 120px;
}
</style>